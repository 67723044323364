import { IS_LOCAL, IS_PROD } from "./constants";

const isQuantumMetricEnabled = () =>
  import.meta.env.VITE_QUANTUM_METRICS_ENABLED === "true";

export const initQuantumMetric = () => {
  if (IS_LOCAL || !isQuantumMetricEnabled()) return;
  const src = `https://cdn.quantummetric.com/qscripts/quantum-herbalife${IS_PROD ? "" : "-test"}.js`;
  const qtm = document.createElement("script");
  qtm.type = "text/javascript";
  qtm.async = true;
  qtm.src = src;
  const d = document.getElementsByTagName("script")[0];

  if (!window.QuantumMetricAPI) d?.parentNode?.insertBefore(qtm, d);
};
